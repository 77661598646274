<script>
  import EventTime from "./EventTime.svelte"
  import allEvents from "../data/special_events.yml"
  const futureEvents = allEvents.filter(e => new Date(e.time.end || e.time.start) >= new Date())
</script>

{#if futureEvents.length > 0}
<div class="bt bw1 light-gray"></div>
<div class="ma4 section">
  {#each futureEvents as event}
    <div class="section-left">
      <h4 class="f4 mb1">{event.name}</h4>
      <p class="b"><EventTime time={event.time.start} /></p>
      <p class="lh-title">{event.summary}</p>
      {#if event.button}
        <a class="{event.button.class} f5 no-underline lh-copy grow dib ph3 pv2 br3 ba bw1 fw6 gray"
           href={event.button.url}>{event.button.text}</a>
      {/if}
    </div>
  {/each}
</div>
{/if}
