<script>
  import IcalExpander from "ical-expander";
  import EventList from "./EventList.svelte";

  async function fetchCalendar() {
    const res = await fetch(
      "https://wdsa-calendar-proxy.vercel.app/api/calendar"
    );
    const text = await res.text();
    if (res.ok) {
      return text;
    } else {
      throw new Error(text);
    }
  }

  async function loadIcale() {
    const ics = await fetchCalendar();
    return new IcalExpander({ ics, maxIterations: 100 });
  }
  let promise = loadIcale();
</script>

<h2 class="f2 mt0 mb3">Upcoming Events</h2>
<div id="upcoming-events" class="lh-copy">
  {#await promise}
    <p>Loading...</p>
  {:then icale}
    <EventList {icale} daysAgo={0} daysFromNow={14} />
  {:catch error}
    <p>Error: {error.message}</p>
  {/await}
</div>
<h3 class="f3 mb2">Past Events</h3>
<div id="past-events" class="lh-copy">
  {#await promise}
    <p>Loading...</p>
  {:then icale}
    <EventList
      {icale}
      daysAgo={-180}
      daysFromNow={-1}
      eventLimit={3}
      inverseSort={true}
      eventProps={{ subdued: true }}
    />
  {:catch error}
    <p>Error: {error.message}</p>
  {/await}
</div>
