<script charset="utf-8">
  import dayjs from "dayjs";
  import calendar from "dayjs/plugin/calendar";
  import advancedFormat from "dayjs/plugin/advancedFormat";
  dayjs.extend(calendar);
  dayjs.extend(advancedFormat);

  export let time;

  const LT = "h:mm A";
  const L = `MMMM Do [at] ${LT}`;
  const calendarFormat = {
    lastDay: `[Yesterday at] ${LT}`,
    sameDay: `[Today at] ${LT}`,
    nextDay: `[Tomorrow at] ${LT}`,
    nextWeek: `dddd [at] ${LT}`,
    lastWeek: `[Last] dddd [at] ${LT}`,
    sameElse: L,
  };
</script>

{dayjs(time).calendar(null, calendarFormat)}
