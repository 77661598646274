<script>
  import EventTime from "./EventTime.svelte";

  export let event;
  export let subdued = false;

  let detailed = !subdued && event?.description?.length > 0;
</script>

<div class="event" class:subdued>
  <strong>{event.summary}</strong>
  {#if detailed}
    <details>
      <summary>
        <EventTime time={event.startDate} />
      </summary>
      <div class="measure-wide lh-title">{@html event.description}</div>
    </details>
  {:else}
    <div class="time">
      <EventTime time={event.startDate} />
    </div>
  {/if}
</div>

<style>
  .event {
    /* Equivalent of mb2 */
    margin-bottom: 0.5rem;
  }
  .subdued.event {
    opacity: 0.75;
    /* Equivalent of mb1 */
    margin-bottom: 0.25rem;
  }
  .subdued .time {
    display: inline;
    opacity: 0.75;
  }
  details > summary {
    list-style-type: none;
    cursor: pointer;
    transition: 0.3s all;
  }

  details > summary::-webkit-details-marker {
    display: none;
  }
  details > summary::before {
    content: "▶";
    padding-right: 0.25em;
    opacity: 0.5;
  }

  details[open] > summary::before {
    content: "▼";
  }

  details[open] {
    margin-bottom: 2rem;
  }

  details[open] summary {
    margin-bottom: 0.5rem;
  }
</style>
