<script>
  import Event from "./Event.svelte";

  export let icale;
  export let daysAgo;
  export let daysFromNow;
  export let eventLimit = false;
  export let inverseSort = false;
  export let eventProps = {};

  function relativeDays(n) {
    var today = new Date();
    var nextweek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + n
    );
    return nextweek;
  }

  const icaleEvents = icale.between(
    relativeDays(daysAgo),
    relativeDays(daysFromNow)
  );

  const mappedEvents = icaleEvents.events.map((e) => ({
    startDate: e.startDate,
    endDate: e.endDate,
    summary: e.summary,
    description: e.description,
  }));
  const mappedOccurrences = icaleEvents.occurrences.map((o) => ({
    startDate: o.startDate,
    endDate: o.endDate,
    summary: o.item.summary,
    description: o.item.description,
  }));
  const allEvents = [].concat(mappedEvents, mappedOccurrences);
  console.debug({ daysAgo, daysFromNow, allEvents });
  const regularSorter = (a, b) => new Date(a.startDate) - new Date(b.startDate);
  const inverseSorter = (a, b) => new Date(b.startDate) - new Date(a.startDate);
  let sortedEvents = allEvents.sort(
    inverseSort ? inverseSorter : regularSorter
  );
  if (eventLimit) {
    sortedEvents = sortedEvents.slice(0, eventLimit);
  }
</script>

{#each sortedEvents as event}
  <Event {event} {...eventProps} />
{/each}
